import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from 'moment'

import * as C from './styled'
import * as G from '../../../styles/global'
import * as M from '../../../styles/modal'

import Api from '../../../services/api'
import FinancialApi from '../../../services/financialApi'
import { UserContext } from '../../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LoginIcon from '@mui/icons-material/Login';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import WarningIcon from '@mui/icons-material/Warning';
import BusinessIcon from '@mui/icons-material/Business';
import BadgeIcon from '@mui/icons-material/Badge';
import ConstructionIcon from '@mui/icons-material/Construction';
import BallotIcon from '@mui/icons-material/Ballot';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { border, borderBottom, borderRadius, fontSize, maxHeight, padding, style, width } from '@mui/system';



export const Receipts = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, watch, formState: { errors }, reset} = useForm({ mode: 'all' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState(true)
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)

  const [bankAccount, setBankAccount] = useState([])
  const [receiptcategory, setReceiptCategory] = useState([])
  const [accountPlan, setAccountPlan] = useState([])
  const [costCenter, setCostCenter] = useState([])
  const [paymentMethod, setPaymentMethod] = useState([])
  const [postingState, setPostingState] = useState([])
  const [entity, setEntity] = useState([])
  const [entityType, setEntityType] = useState([])
  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [receiptStatus, setReceiptStatus] = useState('')
  const [selectedReceiptId, setSelectedReceiptId] = useState('')
  
  // const [employe, setEmploye] = useState([])
  // const [supplier, setSupplier] = useState([])
  // const [customer, setCustomer] = useState([])
  // const [transportCompany, setTransportCompany] = useState([])
  // const [company, setCompany] = useState([])
  // const [lawyer, setLawyer] = useState([])

  const [file, setFile] = useState([])
  const [fileName, setFileName] = useState([])
  const [markDeleteFile, setMarkDeleteFile] = useState(false)

  // const year = new Date().getFullYear()
  // const month = new Date().getMonth() +1
  var firstday = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD');
  var lastday = moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(firstday)
  const [endDate, setEndDate] = useState(lastday)

  const [grossValue, setGrossValue] = useState('0')
  const [discountValue, setDiscountValue] = useState('0')
  const [interestValue, setInterestValue] = useState('0')
  const [totalValue, setTotalValue] = useState('0')

  const [description, setDescription] = useState('')
  const [id, setID] = useState('')
  const [state_id, setState_id] = useState('')
  const [entityName, setEntityName] = useState('')
  const [value1, setValue1] = useState('')
  const [value2, setValue2] = useState('')
  const [account, setAccount] = useState('')

  const [quota, setQuota] = useState(1)
  
  const [showAdvancedSearch, setshowAdvancedSearch] = useState(false)


  /// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('asc')
  const [activeOrder, setActiveOrder] = useState('')

  const isParcel =  paymentMethod.filter(qitem => qitem.id === parseInt(watch("payment_method_id")))

  
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
        return data.description.toLowerCase().search(value) !== -1 
            || data.name_entity.toLowerCase().search(value) !== -1 
            || data.receipt_category_description.toLowerCase().search(value) !== -1 
            || data.due_date.toLowerCase().search(value) !== -1 
            || data.posting_state_description.toLowerCase().search(value) !== -1 
        })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }
  


  /// FUNCTIONS ////////////////////////////////////////////////////////////////


  const loadBases =  async () => {

    let res1 = await FinancialApi.getReceiptCategory()
    setReceiptCategory(res1)

    let res2 = await FinancialApi.getAccountPlan()
    setAccountPlan(res2)

    let res3 = await FinancialApi.getBankAccount()
    setBankAccount(res3)

    let res4 = await FinancialApi.getCostCenter()
    setCostCenter(res4)

    let res5 = await FinancialApi.getPaymentMethod()
    setPaymentMethod(res5)

    let res6 = await FinancialApi.getPostingState()
    setPostingState(res6)

    let res7 = await FinancialApi.getEntity()
    setEntityType(res7)

  }

  const CleanSearch = () => {
    setDescription('')
    setID('')
    setState_id('')
    setEntityName('')
    setValue1('')
    setValue2('')
    setSelectedEntityId('')
    setFilteredList([])
  }  

  

  const GetSelect =  async (value) => {
    
    setSelectedEntityId(value)

    switch (value) {
      case '1':
        let res1 = await FinancialApi.getCustomer()
        setEntity(res1)
        break;

      case '2':
        let res2 = await FinancialApi.getSupplier()
        setEntity(res2)
        break;
    
      case '3':
        let res3 = await FinancialApi.getEmployee()
        setEntity(res3)
        break;
                
      case '4':
        let res4 = await FinancialApi.getOther()
        setEntity(res4)
        break;
    
      default:
        break;
    }

  }


  const GetReceipt = async () => {
    setLoading(true)
    let res = await FinancialApi.getReceipt(startDate, endDate, description ? `&description=${description}` : '', id ? `&id=${id}` : '',
                                                                state_id ? `&state_id=${state_id}` : '' , entityName ? `&entityName=${entityName}` : '',
                                                                value1 ? `&value1=${value1.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")}` : '',
                                                                value2 ? `&value2=${value2.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")}` : '',
                                                                account ? `&account=${account}` : ''   
    );
    setList(res)
    setFilteredList(res)
    setLoading(false)
  }


  const onSubmit = async (data) => {

    if(file.length !== 0) {
      let res = await FinancialApi.uploadFile(file)
      data["attachment"] = file.name
    }
    if(markDeleteFile === true) {
      Delete(filteredList[selectedKey].attachment)
      data["attachment"] = null
    }
    switch (modalType) {
      case 1:
        const userAdd = ({
          created_by: user.id,
          changed_by: user.id
        })
        const add = ({...data, ...userAdd})
        for ( var i = 0; i < quota; i++ ) { 
          add['due_date'] = moment(data.due_date).add(i, 'M').format('YYYY-MM-DD')
          let res1 = await FinancialApi.postReceipt(add)
        }
      break;

      case 4:
        const userDup = ({
          created_by: user.id,
          changed_by: user.id
        })
        const dup = ({...data, ...userDup})
          delete dup.id
          for ( var i = 0; i < quota; i++ ) { 
            dup['due_date'] = moment(data.due_date).add(i, 'M').format('YYYY-MM-DD')
            let res1 = await FinancialApi.postReceipt(dup)
          }
      break;

      case 2:
        const userUpd = ({
          changed_by: user.id
        })
        const upd = ({...data, ...userUpd})
        let res2 = await FinancialApi.postReceipt(upd)
      break;

      default:
      break;
    }

    CloseModalClick()
  };


  const DelReceipt = async (id) => {
    let res = await FinancialApi.delReceipt(id);
    GetReceipt()
  }


  const Download = (file) => {
    window.open(`${process.env.REACT_APP_BACKEND}/download?file=${file}&token=${localStorage.getItem('SessionID')}`)
  }


  const Delete = async (file) => {
    // window.open(`${process.env.REACT_APP_BACKEND}/download?file=${file}`)
    if(file.length !== 0) {
      let res = await FinancialApi.deleteFile(file)
    } else {
    }
  }


  const ValorTotal = (event) => {
    // console.log("FuncValorTotal", event)
  }



  const AddClick = () => {
    setModalType(1)
    loadBases()
    setModal(true)
  }  

  
  const EditClick = (key) => {
    setSelectedKey([key])
    setSelectedReceiptId(filteredList[key].id)
    setSelectedEntityId(filteredList[key].entity_id)
    setGrossValue(filteredList[key].gross_value)
    setDiscountValue(filteredList[key].discount_value)
    setInterestValue(filteredList[key].interest_value)
    setTotalValue(filteredList[key].total_value)
    setModalType(2)
    loadBases()
    setModal(true)
  }

  const DuplicateClick = (key) => {
    setSelectedKey([key])
    setSelectedReceiptId(filteredList[key].id)
    setSelectedEntityId(filteredList[key].entity_id)
    setGrossValue(filteredList[key].gross_value)
    setDiscountValue(filteredList[key].discount_value)
    setInterestValue(filteredList[key].interest_value)
    setTotalValue(filteredList[key].total_value)
    setModalType(4)
    loadBases()
    setModal(true)
  }


  const DelClick = async (key) => {
    setSelectedKey([key])
    setModalType(3)
    setModal(true)
  }


  const CloseModalClick = () => {
    setLoading(true)
    setSelectedKey('')
    setSelectedReceiptId('')
    setAlertError('')
    setGrossValue('0')
    setDiscountValue('0')
    setInterestValue('0')
    setTotalValue('0')
    GetReceipt()
    setModal(false)
    setModalType('')
    setFileName([])
    setMarkDeleteFile(false)
    reset()
    setLoading(false)
  }


  const CloseModalClick2 = () => {
    setLoading(true)
    setSelectedKey('')
    setSelectedReceiptId('')
    setAlertError('')
    setGrossValue('0')
    setDiscountValue('0')
    setInterestValue('0')
    setTotalValue('0')
    setModal(false)
    setModalType('')
    setFileName([])
    setMarkDeleteFile(false)
    reset()
    setLoading(false)
  }





/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    GetReceipt()
    loadBases()
  }, [state])


  useEffect(()=>{ 
    
  }, [grossValue, discountValue, interestValue, totalValue]);



/// CONSOLE LOG ////////////////////////////////////////////////////////////////

  // console.log(moment('2024-12-20T03:00:00.000Z').isSameOrBefore(moment(), 'day'))
  
  

/// RETURN /////////////////////////////////////////////////////////////////////

  return (
    <G.Container>
      <G.Header>
      <G.HeaderTitleArea style={{ width: '240px' }}>
          <LoginIcon style={{ fontSize: '32px', margin: '0px 0px 0px 0px', transform: 'rotate(90deg)' }} />
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <G.MainTitle style={{ fontSize: 'var(--font-size-title)' }}>Recebimentos</G.MainTitle>
          <div style={{marginLeft: '10px', backgroundColor: '#333', padding: '7px', color: '#fff', fontWeight: 'bold', borderRadius: '5px', width: 30, justifyContent: 'center', display: 'flex'}}>{filteredList.length}</div>
        </G.HeaderTitleArea>
        <G.SearchArea style={{gap: '20px'}}>
          <G.SwitchArea style={{gap: '10px'}} >
          <div style={{borderBottom: '3px solid green', padding: '7px', color: '#000',  fontWeight: 'bold', gap: '5px', flexDirection: 'row', display: 'flex', width: 140}}>
          <G.Text>Aberto:</G.Text>
            <G.Text style={{fontSize: '14px'}}>
            {filteredList.filter(qitem => qitem.posting_state_id === 1)
                         .reduce((a, b) => a + parseFloat(b.gross_value)  - parseFloat(b.discount_value) + parseFloat(b.interest_value), 0)
                         .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </G.Text>
          </div>
          <div style={{borderBottom: '3px solid red', padding: '7px', color: '#000',  fontWeight: 'bold', gap: '5px', flexDirection: 'row', display: 'flex', width: 130}}>
          <G.Text>Venc:</G.Text>
            <G.Text style={{fontSize: '14px'}}>
             {filteredList.filter(qitem => qitem.posting_state_id === 1 && moment(qitem.due_date)
                          .isSameOrBefore(moment(), 'day')).reduce((a, b) => a + parseFloat(b.gross_value)  - parseFloat(b.discount_value) + parseFloat(b.interest_value), 0)
                          .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </G.Text>
          </div>
          <div style={{borderBottom: '3px solid blue', padding: '7px', color: '#000', fontWeight: 'bold', gap: '5px', flexDirection: 'row', display: 'flex', width: 130}}>
            <G.Text>Total:</G.Text>
            <G.Text style={{fontSize: '14px'}}>
            {filteredList.reduce((a, b) => a + parseFloat(b.gross_value)  - parseFloat(b.discount_value) + parseFloat(b.interest_value), 0)
                         .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </G.Text>

          </div> 
          </G.SwitchArea>
          <G.SwitchArea>
            <SearchIcon />
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SwitchArea>
          <G.SwitchArea style={{ width: '220px', justifyContent: 'end' }}>
            <G.Button style={{ backgroundColor: '#1E90FF', justifyContent: 'space-around'}} onClick={() => setshowAdvancedSearch(true) + setFilteredList([])}>
              <SearchIcon fontSize='small'/>
              Avançada
            </G.Button>
            <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
              <AddIcon fontSize='small'/>
              {'Adicionar'}
            </G.Button>
          </G.SwitchArea>
        </G.SearchArea>
      </G.Header>
      {showAdvancedSearch &&
      <G.Header style={{minHeight: '170px', justifyContent: 'center', alignItems: 'start', backgroundColor: '#fff', padding: '10px', width: '970px'}}>
        <G.SearchArea style={{gap: '10px', flexWrap: 'wrap', justifyContent: 'start'}}>
          <G.SwitchArea style={{gap: '3px'}} >
            <G.Select  style={{padding: '5px', borderRadius: '5px', color: '#000'}} onChange={(e) => GetSelect(e.target.value)}>
                    <option> Selecione </option>
                    {entityType.map(i => (
                        <option key={i.id} value={i.id}>{i.name}</option>
                      ))}
            </G.Select>
            <G.Select onChange={(e) => setEntityName(e.target.value)} value={entityName} style={{width: '500px'}} >
                    {entity.map(i => (
                        <option 
                        key={selectedEntityId === '1' ? i.customer_id // Cliente
                          : selectedEntityId === '2' ? i.id // Fornecedor
                          : selectedEntityId === '3' ? i.id  // Funcionário
                          : selectedEntityId === '4' ? i.id  // Outro
                          : undefined
                        } 
                        value={selectedEntityId === '1' ? i.name // Cliente
                            : selectedEntityId === '2' ? i.company_name // Fornecedor
                            : selectedEntityId === '3' ? i.name // Funcionário
                            : selectedEntityId === '4' ? i.company_name  // Outro
                            : undefined
                        }
                     >
                        {selectedEntityId === '1' ? i.name // Cliente
                          : selectedEntityId === '2' ? i.company_name // Fornecedor
                          : selectedEntityId === '3' ? i.name // Funcionário
                          : selectedEntityId === '4' ? i.company_name  // Outro
                          : undefined
                        }
                      </option>
                      ))}
            </G.Select>
          </G.SwitchArea>
          <G.SwitchArea >
            <G.Select onChange={(e) => setState_id(e.target.value)} >
              <option> Selecione </option>
                  {postingState.map(i => (
                    <option key={i.id} value={i.id}>{i.description}</option>
                  ))}
           </G.Select>
          </G.SwitchArea>
          <G.SwitchArea >
            <G.Select onChange={(e) => setAccount(e.target.value)} >
              <option> Selecione </option>
                  {bankAccount.map(i => (
                    <option key={i.id} value={i.id}>{i.name}</option>
                  ))}
           </G.Select>
          </G.SwitchArea>
          <G.SwitchArea style={{color: '#000'}} >
              <G.Input placeholder={'R$ 0,00'} value={value1} onChange={e => setValue1(e.target.value)} style={{ textAlign: 'center', width: '105px'}}/>--
              <G.Input placeholder={'R$ 0,00'} value={value2} onChange={e => setValue2(e.target.value)} style={{ textAlign: 'center', width: '105px'}}/>
          </G.SwitchArea>
          <G.SwitchArea >
              <G.Input value={description} onChange={(e) => setDescription(e.target.value)} placeholder={'Descrição'} style={{width: '510px'}}/>
          </G.SwitchArea>
          <G.SwitchArea >
            <G.Input value={id}  onChange={(e) => setID(e.target.value)} placeholder={'ID'} style={{width: '100px'}}/>
          </G.SwitchArea>
          <G.SwitchArea style={{gap: '3px'}} >
            <G.Input type='date' style={{ textAlign: 'center'}} value={startDate} onChange={e => setStartDate(e.target.value)}/>
            <G.Input type='date' style={{ textAlign: 'center'}} value={endDate}  onChange={e => setEndDate(e.target.value)} />
          </G.SwitchArea>
          <G.SwitchArea style={{ width: '300px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#E95839', justifyContent: 'space-around'}} onClick={() => CleanSearch() + setshowAdvancedSearch(false) +   GetReceipt()}>
              Fechar
            </G.Button>
            <G.Button style={{ backgroundColor: '#FCC703', justifyContent: 'space-around'}} onClick={CleanSearch}>
              Limpar
            </G.Button>
            <G.Button style={{ backgroundColor: '#1E90FF', justifyContent: 'space-around'}} onClick={GetReceipt}>
              <SearchIcon fontSize='small'/>
              Pesquisar
            </G.Button>
          </G.SwitchArea>
        </G.SearchArea>
      </G.Header>
      }

      <G.Body>
        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>
              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px', gap: '10px' }}>
                  <G.FieldArea size={'40px'} style={{ cursor: 'pointer', justifyContent: 'center' }} onClick={() => OrderClick('id')}><G.DistacInfoText>ID</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'170px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('description')}><G.DistacInfoText>Descrição</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'description') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'300px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('name_entity')}><G.DistacInfoText>Entidade</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'name_entity') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'130px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('receipt_category_id')}><G.DistacInfoText>Categoria</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'receipt_category_id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'80px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('receipt_category_id')}><G.DistacInfoText>Conta</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'receipt_category_id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'70px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('due_date')}><G.DistacInfoText>Vencimento</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'due_date') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'50px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('total_value')}><G.DistacInfoText>Total</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'total_value') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'84px'} style={{ cursor: 'pointer', padding: '0px 5px', margin: '0px 5px' }} onClick={() => OrderClick('posting_state_id')}><G.DistacInfoText>Estado</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'posting_state_id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'50px'} style={{ justifyContent: 'center' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                {/* {filteredList.length === 0 && 
                  <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                    *** Não existem itens a exibir para o período selecionado. ***
                  </G.Text>
                } */}
                {filteredList.length > 0 &&
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key} style={{gap: '10px'}} >
                        <G.FieldArea size={'40px'} style={{justifyContent: 'center', padding: '0px'}}>
                          <div style={{display: 'flex', justifyContent: 'center', width: '40px',fontSize: '11px', border: '1px solid #555', fontWeight: 'bold', padding: '7px', borderRadius: '5px'}}>{item.id}</div>
                          </G.FieldArea>
                        <G.FieldArea size={'200px'} style={{justifyContent: 'start'}}><G.Text>{item.description}</G.Text></G.FieldArea>
                        <G.FieldArea size={'25px'} style={{padding: '0px', borderBottom: '2px solid #ffb400', borderRadius: '0px'}}>
                           {item.entity_id === 1 ? <BusinessIcon/> : item.entity_id === 2 ? <ConstructionIcon/> : item.entity_id === 3 ? <BadgeIcon/> : <BallotIcon/>}
                        </G.FieldArea>
                        <G.FieldArea size={'275px'} style={{justifyContent: 'start'}}><G.Text>{item.name_entity}</G.Text></G.FieldArea>
                        <G.FieldArea size={'100px'}  style={{justifyContent: 'start'}}><G.Text>{item.receipt_category_description}</G.Text></G.FieldArea>
                        <G.FieldArea size={'90px'} style={{justifyContent: 'center'}}>
                        <C.ContactTypeIcon style={{ width: '70px', padding: '4px', fontSize: '11px', borderRadius: '5px', backgroundColor: item.bank_account_color }}>
                          {item.bank_account_name}
                        </C.ContactTypeIcon>
                        </G.FieldArea>
                        <G.FieldArea size={'70px'}><G.Text>{new Date(item.due_date).toLocaleDateString('pt-BR')}</G.Text></G.FieldArea>
                        <G.FieldArea size={'70px'} style={{justifyContent: 'end'}}><G.Text>{(parseFloat(item.gross_value) - parseFloat(item.discount_value) + parseFloat(item.interest_value)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</G.Text></G.FieldArea>
                        <G.FieldArea size={'84px'} style={{ padding: '0px 4px' }}>
                          <C.ContactTypeIcon 
                            style={{ width: '80px', padding: '6px', fontSize: '11px', borderRadius: '20px', backgroundColor: item.posting_state_id === 1 ? '#1E90FF' : item.posting_state_id === 2 ? '#2E8B57' : item.posting_state_id === 3 ? '#E95839' : item.posting_state_id === 4 ? '#FFB400' : '#555' }}
                          >
                            {item.posting_state_description}
                          </C.ContactTypeIcon>
                        </G.FieldArea>
                        <G.FieldArea size={'92px'} style={{ justifyContent: 'flex-end' }}>
                          <G.IconButton onClick={() => EditClick(key) + setFileName(filteredList[key].attachment)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}>
                            <EditIcon fontSize="small" />
                          </G.IconButton>
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px' }}>
                            <DeleteIcon fontSize="small"/>
                          </G.IconButton>
                          <G.IconButton onClick={() => DuplicateClick(key)} style={{ backgroundColor: '#ffb400', margin: '0px 1px' }}>
                        <FileCopyIcon fontSize="small"/>
                         </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                }
              </G.TableBody>
            </G.Box>
          </>
        }

      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center', borderRadius: '12px' }}>
            {/* <M.ModalHeader style={{ backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : '#ccc', justifyContent: 'space-between', bordeRadius: '15px' }}>
              <C.ModalTitle>{modalType === 1 ? 'Adicionar Recebimento' : modalType === 2 ? 'Editar Recebimento' : modalType === 3 ? 'Excluir Recebimento' : ''}</C.ModalTitle>
              <C.ModalTitle style={{ fontWeight: 'normal', marginRight: '16px' }}>{'ID: ' + selectedReceiptId}</C.ModalTitle>
            </M.ModalHeader> */}
            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'start', marginTop: '5px'}}>
              {(modalType !== 1) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].id} {...register("id")} />
              }

              {(modalType === 1 || modalType === 2|| modalType === 4) &&
                <G.BoxBodyScroll style={{ display: 'flex', margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Descrição
                    </G.FormInputLabel>
                    <G.FormInput autoFocus defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].description} {...register("description")} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Categoria
                    </G.FormInputLabel>
                    <G.FormSelect {...register("receipt_category_id", { setValueAs: v => v === '' ? null : v })}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].receipt_category_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].receipt_category_description}</option>
                      {receiptcategory.map(i => (
                        <option key={i.id} value={i.id}>{i.description}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.BreakLine/>

                  {/* <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Classificação
                  </div>

                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel>
                      Plano de Contas
                    </G.FormInputLabel>
                    <G.FormSelect {...register("account_plan_id", { setValueAs: v => v === '' ? null : v })}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].account_plan_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].account_plan_description}</option>
                      {accountPlan.map(i => (
                        <option key={i.id} value={i.id}>{i.description}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel>
                      Centro de Custo
                    </G.FormInputLabel>
                    <G.FormSelect {...register("rcbmt_centro_custo", { setValueAs: v => v === '' ? null : v })}>
                      <option disabled selected value={modalType !== 1 ? filteredList[selectedKey].cost_center_id : ''}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].cost_center_description}</option>
                      {costCenter.map(i => (
                        <option key={i.id} value={i.id}>{i.description}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.BreakLine/> */}

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Dados Recebimento
                  </div>

                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                      Pagamento
                    </G.FormInputLabel>
                    <G.FormSelect {...register("payment_method_id")}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].payment_method_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].payment_method_description}</option>
                      {paymentMethod.map(i => (
                        <option key={i.id} value={i.id}>{i.description}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '476px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                      Conta
                    </G.FormInputLabel>
                    <G.FormSelect {...register("bank_account_id", { setValueAs: v => v === '' ? null : v })}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].bank_account_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].bank_account_name}</option>
                      {bankAccount.map(i => (
                        <option key={i.id} value={i.id}>{i.name}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '232px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Valor Bruto
                    </G.FormInputLabel>
                    <G.FormInput 
                      name={grossValue} 
                      defaultValue={modalType === 1 ? 'R$ 0,00' : parseFloat(filteredList[selectedKey].gross_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} 
                      {...register("gross_value", {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))})} 
                      onChange={(e) => setGrossValue(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '230px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #E95839' }}>
                      Desconto
                    </G.FormInputLabel>
                    <G.FormInput 
                      name={discountValue} 
                      defaultValue={modalType === 1 ? 'R$ 0,00' : parseFloat(filteredList[selectedKey].discount_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} 
                      {...register("discount_value", {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))})} 
                      onChange={(e) => setDiscountValue(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '230px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Juros
                    </G.FormInputLabel>
                    <G.FormInput 
                      name={interestValue} 
                      defaultValue={modalType === 1 ? 'R$ 0,00' : parseFloat(filteredList[selectedKey].interest_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} 
                      {...register("interest_value", {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))})} 
                      onChange={(e) => setInterestValue(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '232px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Total
                    </G.FormInputLabel>
                    <G.FormInput 
                      disabled={true} 
                      name={totalValue} 
                      value={(Number(grossValue) - Number(discountValue) + Number(interestValue)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}
                      // value={
                      //   modalType === 1 
                      //     ? (Number(grossValue) - Number(discountValue) + Number(interestValue)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})
                      //     : (Number(grossValue !== '0' ? grossValue : filteredList[selectedKey].gross_value) - Number(discountValue !== '0' ? discountValue : filteredList[selectedKey].discount_value) + Number(interestValue !== '0' ? interestValue : filteredList[selectedKey].interest_value)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})
                      // }
                      // {...register("total_value", {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))})}
                      {...register("total_value", {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))})}
                      // onChange={(e) => setTotalValue(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))}
                    />
                  </G.FormInputArea>

                  <G.BreakLine/>

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Datas e Estado do Lançamento
                  </div>

                  <G.FormInputArea style={{ width: '250px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Vencimento
                    </G.FormInputLabel>
                    <G.FormInput 
                      type="date" 
                      defaultValue={modalType === 1 ? '' :  moment(filteredList[selectedKey].due_date).format('YYYY-MM-DD')} 
                      {...register("due_date")} 
                    />
                  </G.FormInputArea>
                  {isParcel[0]?.parcel === 1 &&
                    <G.FormInputArea style={{ width: '140px' }}>
                      <G.FormInputLabel  style={{ borderLeft: '3px solid #FCC703' }}>
                        Parcelas
                      </G.FormInputLabel>
                      <G.FormInput disabled={modalType === 2 ? true : false} min="1" onChange={(e) => setQuota(e.target.value)} value={quota} type="number" />
                    </G.FormInputArea>
                    }
                  <div style={{ width: '78px' }} />
                  <G.FormInputArea style={{ width: '280px' }}>
                    <G.FormInputLabel style={{ 
                        borderLeft: modalType === 1 
                          ? '' 
                          : filteredList[selectedKey].posting_state_id === 1 // Estado -- Aberto
                          ? '3px solid #1E90FF' 
                          : filteredList[selectedKey].posting_state_id === 2 // Estado -- Confirmado
                          ? '3px solid #2E8B57' 
                          : filteredList[selectedKey].posting_state_id === 3 // Estado -- Cancelado
                          ? '3px solid #E95839' 
                          : filteredList[selectedKey].posting_state_id === 4 // Estado -- Atrasado
                          ? '3px solid #FFB400' 
                          : '#555' // Estado -- Não definido
                    }}>
                      Estado
                    </G.FormInputLabel>
                    <G.FormSelect {...register("posting_state_id")}>
                      <option value={modalType === 1 ? '' :  filteredList[selectedKey].posting_state_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].posting_state_description}</option>
                      {postingState.map(i => (
                        <option key={i.id} value={i.id} onSelectCapture={() => setReceiptStatus(i.id)}>{i.description}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <div style={{ width: '78px' }} />

                  <G.FormInputArea style={{ width: '250px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Compensação
                    </G.FormInputLabel>
                    <G.FormInput 
                      type="date" 
                      defaultValue={modalType === 1 ? '' :  moment(filteredList[selectedKey].payment_date).format('YYYY-MM-DD')} 
                      {...register("payment_date", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.BreakLine/>

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Entidade
                  </div>

                  <G.FormInputArea style={{ width: '380px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                      Entidade
                    </G.FormInputLabel>
                    <G.FormSelect {...register("entity_id")} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].entity_id} onChange={(e) => GetSelect(e.target.value)}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].entity_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].entity_name}</option>
                      {entityType.map(i => (
                        <option key={i.id} value={i.id}>{i.name}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  {/* <ArrowForwardIosIcon/> */}

                  <G.FormInputArea style={{ width: '572px' }}>
                    {/* <G.FormInputLabel>
                      {modalType === 1 ? 'Entidade' : filteredList[selectedKey].name_entity}
                    </G.FormInputLabel> */}
                    <G.FormSelect {...register("name_entity")}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].name_entity}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].name_entity}</option>
                      {entity.map(i => (
                        <option 
                        key={selectedEntityId === '1' ? i.customer_id // Cliente
                          : selectedEntityId === '2' ? i.id // Fornecedor
                          : selectedEntityId === '3' ? i.id  // Funcionário
                          : selectedEntityId === '4' ? i.id  // Outro
                          : undefined
                        } 
                        value={selectedEntityId === '1' ? i.name // Cliente
                            : selectedEntityId === '2' ? i.company_name // Fornecedor
                            : selectedEntityId === '3' ? i.name // Funcionário
                            : selectedEntityId === '4' ? i.company_name  // Outro
                            : undefined
                        }
                     >
                        {selectedEntityId === '1' ? i.name // Cliente
                          : selectedEntityId === '2' ? i.company_name // Fornecedor
                          : selectedEntityId === '3' ? i.name // Funcionário
                          : selectedEntityId === '4' ? i.company_name  // Outro
                          : undefined
                        }
                      </option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.BreakLine/>

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Documento Fiscal
                  </div>

                  <G.FormInputArea style={{ width: '280px' }}>
                    <G.FormInputLabel>
                      Tipo Doc. Fiscal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].tax_document_type} {...register("tax_document_type", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  
                  <G.FormInputArea style={{ width: '376px' }}>
                    <G.FormInputLabel>
                      Cód. Doc. Fiscal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].tax_document_code_number} {...register("tax_document_code_number", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '280px' }}>
                    <G.FormInputLabel>
                      Série Doc. Fiscal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].tax_document_series_number} {...register("tax_document_series_number", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.BreakLine/>

                  <div style={{ display: 'flex', width: '956px', height: '35px', padding: '1px 1px 2px 1px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Anexo (*)
                  </div>
                  
                  {modalType === 1 && 
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <G.FormInputArea style={{ width: '380px', marginBottom: '0px' }}>
                        {/* <G.FormInputLabel>
                          Anexo
                        </G.FormInputLabel> */}
                        <G.Input type="file" accept=".pdf" style={{ width: '100%', borderRadius: '0px 5px 5px 0px', backgroundColor: '#F5F5F5' }} onChange={e => setFile(e.target.files[0])} />
                      </G.FormInputArea>
                      <p style={{ margin: '2px 0px 0px 8px', color: '#777' }}>(*) Apenas um (1) arquivo no formato PDF.</p>
                    </div>
                  }
                  {modalType !== 1 && 
                    <>
                      {filteredList[selectedKey].attachment === null &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <G.FormInputArea style={{ width: '380px' }}>
                            {/* <G.FormInputLabel>
                              Anexo
                            </G.FormInputLabel> */}
                            <G.Input type="file" accept=".pdf" style={{ width: '100%', borderRadius: '0px 5px 5px 0px', backgroundColor: '#F5F5F5' }} onChange={e => setFile(e.target.files[0])} />
                          </G.FormInputArea>
                          <p style={{ margin: '2px 0px 0px 8px', color: '#777' }}>(*) Apenas um (1) arquivo em formato PDF.</p>
                        </div>
                      }
                      {filteredList[selectedKey].attachment !== null && 
                          // <G.FormInputArea style={{ width: '500px', cursor: 'pointer' }} onClick={() => Download(filteredList[selectedKey].attachment)}>
                          //   <G.FormInputLabel>
                          //     {filteredList[selectedKey].attachment}
                          //   </G.FormInputLabel>
                          // </G.FormInputArea>
                        <>
                          <G.BoxBody>
                            <G.FakeButton style={{ minWidth: '070px', backgroundColor: markDeleteFile === true ? '#aaa' : '#E95839' }}>
                              PDF
                            </G.FakeButton>
                            <G.FakeButton style={{ minWidth: '240px', marginLeft: '-20px', backgroundColor: markDeleteFile === true ? '#888' : '#34495e' }}>
                              {filteredList[selectedKey].attachment}
                            </G.FakeButton>
                            <G.DownloadButton 
                              style={{ minWidth: '030px', marginLeft: '-20px', backgroundColor: markDeleteFile === true ? '#aaa' : '#2E8B57' }} 
                              onClick={() => Download(filteredList[selectedKey].attachment)}
                            >
                              <FileDownloadIcon />
                            </G.DownloadButton>
                          </G.BoxBody>

                          <G.BoxBody>
                            <G.IconButton 
                              style={{ backgroundColor: markDeleteFile === false ? '#E95839' : '#1E90FF', margin: '0px 1px' }} 
                              onClick={() => setMarkDeleteFile(markDeleteFile === false ? true : false)}
                            >
                              {markDeleteFile === false ? <DeleteIcon fontSize="small"/> : <ClearIcon fontSize="small"/>}
                            </G.IconButton>
                          </G.BoxBody>
                        </>
                      }
                    </>
                  }


                  <G.BreakLine style={{ height: '24px' }}/>

                  <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '10px', marginBottom: '10px' }}>
                    <G.FormInputLabel style={{ width: '936px', justifyContent: 'flex-start', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                      Observação
                    </G.FormInputLabel>
                    <G.TextArea {...register("note", { setValueAs: v => v === '' ? null : v })} 
                          style={{ width: '916px', height: '150px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                    >
                      {modalType === 1 ? '' :  filteredList[selectedKey].note}
                    </G.TextArea>
                  </G.FieldArea>

                </G.BoxBodyScroll>
              }
            </M.ModalBody>
            
            

            {(modalType === 3) && 
              <>
                {/* <M.ModalContentSmall> */}

                  {/* <M.ModalHeader style={{ backgroundColor: '#FCAC9B', justifyContent: 'space-between', bordeRadius: '15px' }}>
                    <C.ModalTitle>{'Excluir Recebimento'}</C.ModalTitle>
                    <G.InfoArea>
                      <HighlightOffIcon onClick={CloseModalClick} style={{ cursor: 'pointer',color: '#333' }} fontSize="large" />
                    </G.InfoArea>
                  </M.ModalHeader> */}

                  <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <G.Text style={{ margin: '10px 0px' }}>
                      Deseja excluir o Recebimento:
                    </G.Text>
                    <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                      <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                        {filteredList[selectedKey].description}
                      </G.FormInputLabel>
                    </G.FormInputArea>
                  </M.ModalBody>

                  {/* <M.ModalFooter>
                    <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                    <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelReceipt(filteredList[selectedKey].id) + CloseModalClick()}>Sim</G.Button>
                  </M.ModalFooter> */}

                {/* </M.ModalContentSmall> */}
              </>
            }


            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2 || modalType === 4) &&
                <>
                  <G.Button style={{ backgroundColor: '#E95839' }} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57' }} onClick={() => handleSubmit(onSubmit)()}>{modalType === 1 ? 'Criar' : modalType === 2 ? 'Salvar' : modalType === 4 ? 'Duplicar' : 'Enviar'}</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => DelReceipt(filteredList[selectedKey].id) + CloseModalClick()}>Sim</G.Button>
                </>
              }

            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }
    </G.Container>
  );
}

