import { Routes, Route      } from "react-router-dom";
import { Layout             } from '../container/Layout';
import { Dashboard          } from '../pages/Dashboard';
import { Tickets            } from "../pages/Tickets";

// REGISTRATION ----------------------------------------------------------------
import { Customers          } from "../pages/Financial/Customers";
import { Employees          } from "../pages/Financial/Employees";
import { MyCompany          } from "../pages/Financial/MyCompany";
import { Others             } from "../pages/Financial/Others";
import { ServiceTypes       } from "../pages/Financial/ServiceTypes";
import { Services           } from "../pages/Financial/Services";
import { Suppliers          } from "../pages/Financial/Suppliers";
// -----------------------------------------------------------------------------

// FINANCIAL -------------------------------------------------------------------
import { BankAccounts       } from "../pages/Financial/BankAccounts";
import { Banks              } from "../pages/Financial/Banks";
import { FinancialDashboard } from "../pages/Financial/FinancialDashboard";
import { PaymentCategories  } from "../pages/Financial/PaymentCategories";
import { Payments           } from "../pages/Financial/Payments";
import { ReceiptCategories  } from "../pages/Financial/ReceiptCategories";
import { Receipts           } from "../pages/Financial/Receipts";
import { AccountsTransfer   } from "../pages/Financial/AccountsTransfer";


// -----------------------------------------------------------------------------

// OTHERS ----------------------------------------------------------------------
import { Integration        } from "../pages/Financial/Integration";
// -----------------------------------------------------------------------------

import { Chat               } from "../pages/Chat";
import { Archive            } from "../pages/Archive";
import { Painel             } from "../pages/Painel";
import { Login              } from '../pages/Login';
import { Renew              } from '../pages/Renew';
import { Template           } from "../pages/Template";





export const MainRoute = () => {

  return (
    <Routes>
      <Route path="/"                    element={<Layout/>}>
        <Route path="Dashboard"          element={<Dashboard/>}/>
        <Route path="Tickets"            element={<Tickets/>}/>

        {/* REGISTRATION ---------------------------------------------------------------- */}
        <Route path="Customers"          element={<Customers/>}/>
        <Route path="Employees"          element={<Employees/>}/>
        <Route path="MyCompany"          element={<MyCompany/>}/>
        <Route path="Others"             element={<Others/>}/>
        <Route path="ServiceTypes"       element={<ServiceTypes/>}/>
        <Route path="Services"           element={<Services/>}/>
        <Route path="Suppliers"          element={<Suppliers/>}/>
        {/* ----------------------------------------------------------------------------- */}

        {/* FINANCIAL ------------------------------------------------------------------- */}
        <Route path="BankAccounts"       element={<BankAccounts/>}/>
        <Route path="Banks"              element={<Banks/>}/>
        <Route path="FinancialDashboard" element={<FinancialDashboard/>}/>
        <Route path="PaymentCategories"  element={<PaymentCategories/>}/>
        <Route path="Payments"           element={<Payments/>}/>
        <Route path="ReceiptCategories"  element={<ReceiptCategories/>}/>
        <Route path="Receipts"           element={<Receipts/>}/>
        <Route path="AccountsTransfer"   element={<AccountsTransfer/>}/>

        {/* ----------------------------------------------------------------------------- */}

        {/* OTHERS ---------------------------------------------------------------------- */}
        <Route path="Integration"        element={<Integration/>}/>
        {/* ----------------------------------------------------------------------------- */}

        <Route path="Chat"               element={<Chat/>}/>
        <Route path="Archive"            element={<Archive/>}/>
        <Route path="Painel"             element={<Painel/>}/>

      </Route>

      <Route path="/Login"               element={<Login/>}/>
      <Route path="/Renew"               element={<Renew/>}/>
      
      <Route path="/Template"            element={<Template/>}/>

    </Routes>
  );

}
