import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import moment, { isDate } from 'moment'

import * as C from './styled'
import * as G from '../../../styles/global'
import * as M from '../../../styles/modal'

import Api from '../../../services/api'
import FinancialApi from '../../../services/financialApi'
import { UserContext } from '../../../contexts/UserContext';


import LogoutIcon from '@mui/icons-material/Logout';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';




export const AccountsTransfer = () => {

  const navigate = useNavigate();

  const { state: user } = useContext(UserContext)

  const [loading, setLoading] = useState('')


  const [fromAccount, setFromAccount] = useState('origem')
  const [toAccount, setToAccount] = useState('destino')
  const [fromCategory, setFromCategory] = useState('')
  const [toCategory, setToCategory] = useState('')
  const [description, setDescription] = useState('')
  const [grossValue, setGrossValue] = useState('')
  const [payment, setPayment] = useState('')
  const [transfDate, setTransfDate] = useState(moment().format('YYYY-MM-DD'))


  const [accountPlan, setAccountPlan] = useState([])
  const [paymentCategory, setPaymentCategory] = useState([])
  const [receiptCategory, setReceiptCategory] = useState([])
  const [bankAccount, setBankAccount] = useState([])
  const [paymentMethod, setPaymentMethod] = useState([])
  const [postingState, setPostingState] = useState([])



  const loadBases =  async () => {

    let res1 = await FinancialApi.getAccountPlan()
    setAccountPlan(res1)

    let res2 = await FinancialApi.getPaymentCategory()
    setPaymentCategory(res2)

    let res3 = await FinancialApi.getBankAccount()
    setBankAccount(res3)

    let res4 = await FinancialApi.getReceiptCategory()
    setReceiptCategory(res4)

    let res5 = await FinancialApi.getPaymentMethod()
    setPaymentMethod(res5)

    let res6 = await FinancialApi.getPostingState()
    setPostingState(res6)
  }

  const postTransf =  async () => {

     let res1 = await FinancialApi.postPayment(
        {
          bank_account_id: fromAccount,
          description: description,
          due_date: transfDate,
          gross_value: grossValue,
          name_entity: "JOY SOLUÇÕES E TECNOLOGIA LTDA",
          payment_category_id: fromCategory,
          payment_date:  transfDate,
          payment_method_id: payment,
          posting_state_id: "2",
          created_by: user.id,
          changed_by: user.id
        }
     )
     console.log(transfDate)

     let res2 = await FinancialApi.postReceipt(
       {
        bank_account_id: toAccount,
        description: description,
        due_date: transfDate,
        gross_value: grossValue,
        name_entity: "JOY SOLUÇÕES E TECNOLOGIA LTDA",
        receipt_category_id: toCategory,
        payment_date:  transfDate,
        payment_method_id: payment,
        posting_state_id: "2",
        created_by: user.id,
        changed_by: user.id
       }
    )
     console.log(res2)

     setLoading(true)

  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    loadBases()
  }, []);



/// CONSOLE LOG ////////////////////////////////////////////////////////////////

  console.log(toAccount)
  // console.log(description , id, state_id, entityName, value1, value2)


  
  

/// RETURN /////////////////////////////////////////////////////////////////////

  return (
    <G.Container>
      <G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <PublishedWithChangesIcon style={{ fontSize: '32px', margin: '0px 0px 0px 0px' }} />
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <G.MainTitle style={{ fontSize: 'var(--font-size-title)' }}>Trasferências</G.MainTitle>
        </G.HeaderTitleArea>
      </G.Header>
      {loading &&
      <G.Body style={{height: '400px', alignItems: 'center'}}>
        <G.Image src={'/success.gif'}/>
        <G.Button onClick={() => setLoading(false) } style={{ backgroundColor: '#2E8B57', width: '250px', marginTop: '30px' }} >Realizar nova tranferência</G.Button>
        </G.Body>
      } 
      {!loading &&
      <G.Body style={{alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: 20, width: '100%', flexDirection: 'row', marginTop: '20px'}}>
      <G.BoxBody style={{ backgroundColor: '#f5f5f5', display: 'flex', borderRadius: '5px', justifyContent: 'center', width: 'auto', flexDirection: 'column', alignItems: 'center', borderLeft: '3px solid red'}}>
         <G.FormInputArea style={{ width: '450px' }}>
            <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
              Conta
            </G.FormInputLabel>
            <G.FormSelect onChange={(e) => setFromAccount(e.target.value)}>
              <option>Selecione Origem</option>
              {bankAccount.map(i => (
                <option key={i.id} value={i.id}>{i.name}</option>
              ))}
            </G.FormSelect>
          </G.FormInputArea>
          <G.FormInputArea style={{ width: '450px' }}>
            <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
              Categoria
            </G.FormInputLabel>
            <G.FormSelect  onChange={(e) => setFromCategory(e.target.value)} >
              <option >Selecione</option>
              {paymentCategory.map(i => (
                <option key={i.id} value={i.id}>{i.description}</option>
              ))}
            </G.FormSelect>
          </G.FormInputArea>

          </G.BoxBody>
          <G.BoxBody style={{backgroundColor: '#f5f5f5', display: 'flex', borderRadius: '5px', justifyContent: 'center', width: 'auto', flexDirection: 'column', alignItems: 'center', borderLeft: '3px solid green'}}>
          <G.FormInputArea style={{ width: '450px', border: fromAccount === toAccount ? '1px solid red' : 'none' }}>
            <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
              Conta
            </G.FormInputLabel>
            <G.FormSelect onChange={(e) => setToAccount(e.target.value)}>
              <option>Selecione Destino</option>
              {bankAccount.map(i => (
                <option key={i.id} value={i.id}>{i.name}</option>
              ))}
            </G.FormSelect>
          </G.FormInputArea>
          <G.FormInputArea style={{ width: '450px' }}>
            <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
              Categoria
            </G.FormInputLabel>
            <G.FormSelect  onChange={(e) => setToCategory(e.target.value)} >
              <option >Selecione</option>
              {receiptCategory.map(i => (
                <option key={i.id} value={i.id}>{i.description}</option>
              ))}
            </G.FormSelect>
          </G.FormInputArea>         
          </G.BoxBody>
          <G.BoxBody style={{ marginTop: '-30px', display: 'flex', justifyContent: 'center', width: '70%', flexDirection: 'column', alignItems: 'center'}}>
          <G.FormInputArea style={{ width: '450px' }}>
            <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
              Pagamento
            </G.FormInputLabel>
            <G.FormSelect onChange={(e) => setPayment(e.target.value)} >
              <option >Selecione</option>
              {paymentMethod.map(i => (
                <option key={i.id} value={i.id}>{i.description}</option>
              ))}
            </G.FormSelect>
          </G.FormInputArea>     
          <G.FormInputArea style={{ width: '450px' }}>
            <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
              Descrição
            </G.FormInputLabel>
            <G.FormInput autoFocus onChange={(e) => setDescription(e.target.value)} />
          </G.FormInputArea>
          <G.FormInputArea style={{ width: '450px' }}>
            <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
              Data
            </G.FormInputLabel>
            <G.FormInput type="date" value={transfDate} onChange={(e) => setTransfDate(e.target.value)} />
          </G.FormInputArea>
          <G.FormInputArea style={{ width: '450px' }}>
            <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
              Valor
            </G.FormInputLabel>
            <G.FormInput 
              defaultValue={'R$ 0,00'} 
              onChange={(e) => setGrossValue(e.target.value.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))} 
            />
          </G.FormInputArea>

          </G.BoxBody>
          <G.BoxFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>             
              <>
                <G.Button style={{ backgroundColor: '#ffb400' }} >Limpar</G.Button>
                <G.Button onClick={() => postTransf() } style={{ backgroundColor: '#2E8B57' }} >Transferir</G.Button>
              </>
            </G.BoxFooter>
      </G.Body>
      }         
</G.Container>
  )
}

