import React, { useState, useEffect, useContext, useRef } from 'react';
import { set, useForm } from "react-hook-form";
import moment, { now } from "moment";
import 'moment/locale/pt-br'
import { useWindowSize } from "@uidotdev/usehooks";
import { useNavigate,useSearchParams } from "react-router-dom";
import ReactQuill from 'react-quill';


import './painel.css';
import * as G from '../../styles/global'
import * as M from '../../styles/modal'
import { UserContext } from '../../contexts/UserContext';

import Api from '../../services/api'
import {EmptyAlert} from '../../components/EmptyAlert'
import {PopUpUsers} from '../../components/PopUpUsers';


import BusinessIcon from '@mui/icons-material/Business';
import TaskIcon from '@mui/icons-material/Task';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SpatialAudioIcon from '@mui/icons-material/SpatialAudio';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReplyIcon from '@mui/icons-material/Reply';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PlayArrow from '@mui/icons-material/PlayArrow';
import AddCircle from '@mui/icons-material/AddCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { PageHeader } from '../../components/PageHeader/PageHeader';





export const Painel = ({close}) => {

  const {register, handleSubmit, reset, unregister} = useForm({mode: 'resolver'});
  var momentDurationFormatSetup = require("moment-duration-format");
  const { state: user } = useContext(UserContext)
  const size = useWindowSize();
  const navigate = useNavigate();


	const [list, setList] = useState([]);
	const [ticket, setTicket] = useState(false);
  const [reply, setReply] = useState('')
	const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [modal, setModal] = useState(false)
  const [showDash, setShowDash] = useState(false)
  const [showUsers, setShowUsers] = useState([99])
  const [showButton, setShowButton] = useState(false)
  const [showArticle, setShowArticle] = useState(false)
  const [showReply, setShowReply] = useState(false)
  const [showReplyTemplate, setShowReplyTemplate] = useState(false)
  const [file, setFile] = useState(false)


  
  const [users, setUsers] = useState([]);
  const [type, setType] = useState([]);
  const [sla, setSla] = useState([]);
  const [service, setService] = useState([]);
  const [queue, setQueue] = useState([]);
  const [ticketState, setTicketState] = useState([]);
  const [priority, setPriority] = useState([]);
  const [template, setTemplate] = useState([]);
  const [selectedReplyState, setSelectedReplyState] = useState(false);
  const [selectedReplyDate, setSelectedReplyDate] = useState(false);


  let replyValidate = (reply && selectedReplyState && (selectedReplyState.split(',')[1].trim() === '4' ? selectedReplyDate ? moment(selectedReplyDate).isAfter(moment(), 'hour') ? true : false : false : true ))


const getBases =  async () => {
  let res = await Api.getUser()
  setUsers(res)
  let res1 = await Api.getType()
  setType(res1)
  let res2 = await Api.getSla()
  setSla(res2)
  let res3 = await Api.getService()
  setService(res3)
  let res4 = await Api.getQueue()
  setQueue(res4)
  let res5 = await Api.getState()
  setTicketState(res5)
  let res6 = await Api.getPriority()
  setPriority(res6)
  let res7 = await Api.getTemplate()
  setTemplate(res7)
}

const GoClick = (data) => {
  navigate(data);
}



const selectedTicket = async (id, type) => {
  setLoading(true)
  setTicket(list.filter(qitem => qitem.state_type === type)[id])  
  setLoading(false);
}

const postTicket = async (data) => {
    let res = await Api.postTicket(data);
    setModal(false)
    reset()
    getTickets()
}

const postArticle = async (data) => {
  let res = await Api.postArticle(data)
  let res2 = await Api.postFileArticle(file, res)
  setShowReply(false)
  setShowArticle(false)
  getTickets()
}

	const getTickets = async () => {
    let res = await Api.getTickets()
    if (res) {
        setList(res)
        setTicket(res[0])
                  
    } else {
        
    }
}

useEffect(()=>{
    getTickets()
    getBases()
}, []);

	
useEffect(() => {
  const interval = setInterval(() => {
    getTickets()
  }, 40000); //set your time here. repeat every 5 seconds
  return () => clearInterval(interval);
}, []);

 console.log()

	return (

		<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flex: '1',  marginTop: '15px', fontSize: size.width >= 1600 ? '12px' : '11px'}}>
  		<G.PainelBox style={{ color: '#000', justifyContent: 'center', borderRadius: '10px', width: size.width >= 1600 ? '1300px' : '1000px'}}>
              { (loading === false && list.filter(qitem => qitem.state_type === 1).length === 0) || (showDash) &&
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '95%', height: size.width >= 1600 ?'170px' : '120px', color: '#777',
                             margin: '5px 10px 10px 10px', padding: '2px', borderLeft: '3px solid #ccc', borderRadius: '5px'}}>
                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                      <G.Box style={{ width: '100px', justifyContent: 'start', backgroundColor: '#fff', border: 'none'}}> 
                          <G.Text style={{fontSize: '13px', fontWeight: 'bold', borderLeft: '5px solid green', padding: '3px 10px'}} >Chamados Ativos</G.Text>    
                          <G.Text style={{fontSize: '36px', fontWeight: 'bold'}} >10</G.Text>
                      </G.Box>
                      <G.Box style={{ width: '100px', justifyContent: 'start', backgroundColor: '#fff', border: 'none' }}> 
                          <G.Text style={{fontSize: '13px', fontWeight: 'bold', borderLeft: '5px solid blue', padding: '3px 10px'}} >Criados hoje</G.Text>    
                          <G.Text style={{fontSize: '36px', fontWeight: 'bold'}} >03</G.Text>
                      </G.Box>
                      <G.Box style={{ width: '100px', justifyContent: 'start', backgroundColor: '#fff', border: 'none'}}> 
                          <G.Text style={{fontSize: '13px', fontWeight: 'bold', borderLeft: '5px solid Orange', padding: '3px 10px'}} >Chamados Pendentes</G.Text>    
                          <G.Text style={{fontSize: '36px', fontWeight: 'bold' }} >05</G.Text>
                      </G.Box>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '460px'}}>
                      <G.Box style={{ width: '100px', justifyContent: 'start', backgroundColor: '#fff', padding: '10px 20px', margin: '0px', height: '80px'}}> 
                        <G.Text style={{fontSize: '13px', fontWeight: 'bold', padding: '3px 10px'}}><AssignmentIndIcon style={{ fontSize: '30px', marginRight: '5px' }} /> Chamados Proprietário</G.Text>    
                        <G.Text style={{fontSize: '30px', fontWeight: 'bold'}} >10</G.Text>
                      </G.Box>
                      <G.Box style={{ width: '100px', justifyContent: 'start', backgroundColor: '#fff',  padding: '10px 20px', margin: '0px', height: '80px' }}> 
                        <G.Text style={{fontSize: '13px', fontWeight: 'bold', padding: '3px 10px'}}><SpatialAudioIcon style={{ fontSize: '30px', marginRight: '5px' }} /> Chamados Resposãvel</G.Text>    
                        <G.Text style={{fontSize: '30px', fontWeight: 'bold'}} >03</G.Text>
                      </G.Box>
                      <G.Box style={{ width: '100px', justifyContent: 'start', backgroundColor: '#fff',  padding: '10px 20px', margin: '0px', height: '80px'}}> 
                        <G.Text style={{fontSize: '13px', fontWeight: 'bold', padding: '3px 10px'}}><PlayCircleIcon style={{ fontSize: '30px', marginRight: '5px' }} />Em atendimento</G.Text>    
                        <G.Text style={{fontSize: '24px', fontWeight: 'bold' }} >05h23m</G.Text>
                      </G.Box>
                    </div>
                 </div>
                }
              {(loading === false && list.filter(qitem => qitem.state_type === 1).length !== 0) && (!showDash) &&
              <G.Card style={{ width: '95%', minHeight: '120px', boxShadow: 'none', margin: '5px 10px 0px 10px', borderLeft: '3px solid #84b6f4'}}>
              <G.TableBodyScroll style={{ justifyContent: 'start', marginTop: '0px', height: size.width >= 1600 ?'170px' : '120px'}}>                
                    {list.filter(qitem => qitem.state_type === 1).map((item, key) => (
                      <G.TableArea key={key} style={{ padding: '5px',cursor: 'pointer', backgroundColor: item.tn === ticket.tn ? '#f3fcf2' : ''}} onClick={() => selectedTicket(key, 1) + setReply('' + setShowReply(false))}>
                        <TaskIcon style={{color: '#84b6f4', fontSize: '30px'}}  />
                        <G.FieldArea size={'10%'}><G.Text style={{ fontWeight: 'bold'}}>{item.tn}</G.Text></G.FieldArea>
                        <G.FieldArea style={{justifyContent: 'start'}} size={'30%'}><G.Text >{item.title}</G.Text></G.FieldArea>
                        <G.FieldArea size={'5%'} style={{margin: '0px 25px 0px 10px'}}><BusinessIcon style={{ marginRight: '4px', fontSize: '26px', color: '#ccc' }}/><G.Text  style={{fontWeight: 'bold'}}>{item.customer_id}</G.Text></G.FieldArea>
                        <G.FieldArea size={'10%'} style={{justifyContent: 'start', borderLeft: '4px solid blue', backgroundColor: '#f9f9f9', borderRadius: '3px', alignItems: 'center', marginRight: '5px', padding: '2px 5px'}}><G.Text style={{marginLeft: '5px' }}>{item.state}</G.Text></G.FieldArea>
                        <G.FieldArea size={'15%'} style={{ borderRadius: '3px', alignItems: 'center', marginRight: '2px', padding: '2px '}}>
                           <div style={{ borderRadius: '50%', backgroundColor: '#f9f9f9', height: '30px', width: '30px', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                           <G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '30px', boxShadow: '0px 1px 3px 1px #bbb'}} src={item.customer_photo ? item.customer_photo : '/person.png'} />                          </div>
                          <G.Text style={{marginLeft: '5px'}}>{item.customer}</G.Text>
                        </G.FieldArea>
                        <G.FieldArea size={'15%'} style={{marginRight: '0px'}}>
                          <AccessTimeIcon style={{ marginRight: '4px', fontSize: '26px', color: moment.duration(moment.unix(item.escalation_time).diff(moment())) < 0 ? '#ffc248' : '#ccc'  }}/>
                          <div style={{flexDirection: 'column', width: '100%', marginRight: '10px' }}>
                            <G.Text  style={{borderBottom: '1px solid #aaa', padding: '2px', justifyContent: 'end'}}>
                            {moment.duration(moment.unix(item.escalation_time).diff(moment()), "milliseconds").format("d[d] h[h] mm[m]")}
                            </G.Text>
                            <G.Text  style={{color: '#777', padding: '2px', justifyContent: 'end'}}>1h15m</G.Text>
                          </div>
                          </G.FieldArea>
                        <G.FieldArea size={'70px'} style={{ minWidth: '70px', justifyContent: 'end'}}>
                          <G.IconButton  onClick={() => selectedTicket(key, 1) + setModal(1)} style={{ color: '#333', backgroundColor: '#d9d9d9', margin: '0px 1px' }}><LibraryAddCheckIcon fontSize="small" /></G.IconButton>
                          <G.IconButton  style={{ color: '#777', backgroundColor: '#d9d9d9', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
              </G.TableBodyScroll>
              </G.Card>
                }
              <G.Card style={{ width: '95%',  boxShadow: 'none', minHeight: '150px', borderLeft: '3px solid #51B476'}}>
              <G.TableBodyScroll style={{ justifyContent: 'start', marginTop: '0px', height: size.width >= 1600 ?'260px' : '150px'}}>               
                {(loading === false && list.length !== 0) &&
                  <>
                    {list.filter(qitem => qitem.state_type === 2).map((item, key) => (
                      <G.TableArea key={key} onClick={() => selectedTicket(key, 2) + setReply('') + setFile(false)} style={{ cursor: 'pointer', padding: '5px', backgroundColor: item.tn === ticket.tn ? '#f3fcf2' : ''}} >
                        <TaskIcon style={{color: '#51B476', fontSize: '30px'}}  />
                        <G.FieldArea size={'10%'}><G.Text style={{fontSize: '12px', fontWeight: 'bold'}}>{item.tn}</G.Text></G.FieldArea>
                        <G.FieldArea style={{justifyContent: 'start'}} size={'40%'}><G.Text style={{}}>{item.title}</G.Text></G.FieldArea>
                        <G.FieldArea size={'5%'} style={{margin: '0px 25px 0px 10px'}}><BusinessIcon style={{ marginRight: '4px', fontSize: '26px', color: '#ccc' }}/><G.Text  style={{fontWeight: 'bold'}}>{item.customer_id}</G.Text></G.FieldArea>
                        <G.FieldArea size={'10%'} style={{justifyContent: 'start', borderLeft: '4px solid green', backgroundColor: item.ticket_state_id === 24 ? '#eaffe9' : '#f9f9f9', borderRadius: '3px', alignItems: 'center', marginRight: '5px', padding: '2px 5px'}}><G.Text style={{marginLeft: '5px'}}>{item.state}</G.Text></G.FieldArea>
                        <G.FieldArea style={{width: '3%', cursor: 'pointer', justifyContent: 'center'}} onClick={() => setShowUsers([key, 1])} onMouseOut={() => setShowUsers([])}><G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '32px', boxShadow: '0px 1px 3px 1px #bbb'}} src={item.owner_photo ? item.owner_photo : '/person.png'} />
                          {(showUsers[0] === key && showUsers[1] === 1) &&
                          <PopUpUsers user={item.owner} type={1}/>
                          }
                        </G.FieldArea>
                        <G.FieldArea style={{width: '3%', cursor: 'pointer', justifyContent: 'center'}} onClick={() => setShowUsers([key, 3])} onMouseOut={() => setShowUsers([])} ><G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '32px', boxShadow: '0px 1px 3px 1px #bbb'}} src={item.resp_photo ? item.resp_photo : '/person.png'} />
                          {(showUsers[0] === key && showUsers[1] === 3) &&
                          <PopUpUsers user={item.responsible} type={2}/>
                          }
                        </G.FieldArea>
                        <G.FieldArea size={'15%'} style={{marginRight: '10px'}}><AccessTimeIcon style={{ marginRight: '4px', fontSize: '26px', 
                          color: item.escalation_time === 0 ? '#ccc' : moment.duration(moment.unix(item.escalation_time).diff(moment())) < -1 ? '#FA8072' : '#ccc'  }}/>
                          <div style={{flexDirection: 'column', flex: '1', display: 'flex' }}>
                            <G.Text  style={{borderBottom: '1px solid #aaa', padding: '2px', justifyContent: 'end'}}>
                              {item.escalation_time === 0 ? 'Sem SLA': moment.duration(moment.unix(item.escalation_time).diff(moment()), "milliseconds").format("d[d] h[h] mm[m]")}
                              </G.Text>
                            <G.Text  style={{color: '#777', padding: '2px', justifyContent: 'end'}}>1h15m</G.Text>
                          </div>
                          </G.FieldArea>
                        <G.FieldArea size={'70px'} style={{ minWidth: '70px', justifyContent: 'end'}}>
                          {item.ticket_state_id === 4 &&
                          <G.IconButton onClick={() => postTicket({"id": item.id, "ticket_state_id": 24})} style={{ color: '#777', backgroundColor: '#eaffe9', margin: '0px 1px' }}><PlayArrowIcon fontSize="small" /></G.IconButton>
                          }
                          {item.ticket_state_id === 24 &&
                          <G.IconButton onClick={() => postTicket({"id": item.id, "ticket_state_id": 4})} style={{ color: '#777', backgroundColor: '#fff0e9', margin: '0px 1px' }}><StopIcon fontSize="small" /></G.IconButton>
                          }
                          <G.IconButton  style={{ color: '#777', backgroundColor: '#d9d9d9', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                }
              </G.TableBodyScroll>
              </G.Card>
              <G.Card style={{ width: '95%', boxShadow: 'none', borderLeft: '3px solid #fda14b'}}>
                <G.TableBodyScroll style={{ justifyContent: 'start', marginTop: '0px', height: size.width >= 1600 ?'260px' : '200px'}}>
                  {(loading === false && list.length === 0) && 
                    <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                      *** Não existem itens a exibir. ***
                    </G.Text>
                  }
                  
                  {(loading === false && list.filter(qitem => qitem.state_type === 4).length !== 0) &&
                    <>
                      {list.filter(qitem => qitem.state_type === 4).map((item, key) => (
                        <G.TableArea key={key} onClick={() => selectedTicket(key, 4) + setReply('') + setFile('')}  style={{ cursor: 'pointer', padding: '5px', backgroundColor: item.tn === ticket.tn ? '#f3fcf2' : ''}} >
                          <TaskIcon style={{color: '#fda14b', fontSize: '30px'}}  />
                          <G.FieldArea size={'10%'}><G.Text style={{fontWeight: 'bold'}}>{item.tn}</G.Text></G.FieldArea>
                          <G.FieldArea size={'40%'} style={{ justifyContent: 'start' }}><G.Text style={{}}>{item.title}</G.Text></G.FieldArea>
                          <G.FieldArea size={'5%'} style={{margin: '0px 25px 0px 10px'}}><BusinessIcon style={{ marginRight: '4px', fontSize: '26px', color: '#ccc' }}/><G.Text  style={{fontWeight: 'bold', fontSize: '12px'}}>{item.customer_id}</G.Text></G.FieldArea>
                          <G.FieldArea size={'10%'} style={{justifyContent: 'start', borderLeft: '4px solid orange', backgroundColor: '#f9f9f9', borderRadius: '3px', alignItems: 'center', marginRight: '5px', padding: '2px 5px'}}><G.Text style={{marginLeft: '5px'}}>{item.state}</G.Text></G.FieldArea>
                          <G.FieldArea style={{width: '3%',  cursor: 'pointer'}} onClick={() => setShowUsers([key, 2])} onMouseOut={() => setShowUsers([])}><G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '32px', boxShadow: '0px 1px 3px 1px #bbb'}} src={item.owner_photo ? item.owner_photo : '/person.png'} />
                            {(showUsers[0] === key && showUsers[1] === 2) &&
                             <PopUpUsers user={item.owner} type={1}/>
                            }
                          </G.FieldArea>
                          <G.FieldArea style={{width: '3%',  cursor: 'pointer'}} onClick={() => setShowUsers([key, 4])} onMouseOut={() => setShowUsers([])} ><G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '32px', boxShadow: '0px 1px 3px 1px #bbb'}} src={item.resp_photo ? item.resp_photo : '/person.png'} />
                            {(showUsers[0] === key && showUsers[1] === 4) &&
                              <PopUpUsers user={item.responsible} type={2}/>
                            }
                          </G.FieldArea>
                          <G.FieldArea size={'15%'} style={{marginRight: '10px'}}><AccessTimeIcon style={{ marginRight: '4px', fontSize: '26px', color: moment.duration(moment.unix(item.until_time).diff(moment())) < 0 ? '#ffc248' : '#ccc' }}/>
                              <div style={{flexDirection: 'column', width: '100%' }}>
                              <G.Text  style={{borderBottom: '1px solid #aaa', padding: '2px', justifyContent: 'end'}}>
                              {moment.duration(moment.unix(item.until_time).diff(moment()), "milliseconds").format("d[d] h[h] mm[m]")}
                              </G.Text>
                              <G.Text  style={{color: '#777', padding: '2px', justifyContent: 'end'}}>1h15m</G.Text>
                              </div>
                            </G.FieldArea>
                          <G.FieldArea size={'70px'} style={{ justifyContent: 'end', minWidth: '70px'}}>
                            <G.IconButton  style={{ color: '#777', backgroundColor: '#d9d9d9', margin: '0px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
                            <G.IconButton  style={{ color: '#777', backgroundColor: '#d9d9d9', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                          </G.FieldArea>
                        </G.TableArea>
                      ))}
                    </>
                  }
                </G.TableBodyScroll>
                </G.Card>
4 			</G.PainelBox >
			<G.PainelBox style={{width: '290px', justifyContent: 'start', flexDirection: 'row', alignItems: 'start'}}>

        {!ticket  &&

          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '530px' , border: '1px solid #ccc'}}>
          <EmptyAlert textAlert={'Selecione um chamado para exibir as informações'} />
          </div>
        }
				{ticket  && 
        <div style={{flexDirection: 'row', display: 'flex'}}>
     		<G.Card onMouseOver={() => setShowButton(ticket.ticket_state_id === 1 ? false : true)} onMouseOut={() => setShowButton(false)}  style={{margin: '10px', width: '250px', height: '530px' }}>
          <G.CardHeader style={{height: '50px'}} >
            <TaskIcon style={{color:  ticket.state_type === 1 ? '#84b6f4' : ticket.state_type === 2 ? '#51B476' :ticket.state_type === 4 ? '#fda14b' : '#333', fontSize: '30px'}}  />
            <G.CardHeaderInfo >
              <G.Text><strong>{ticket.tn}</strong> </G.Text>
              < G.Text >{ticket.title}</G.Text>
            </G.CardHeaderInfo>
        </G.CardHeader>
        <G.CardBody>
				<G.CardBodyA style={{ }}>
								<G.CardBodyText style={{ justifyContent: 'flex-start', fontSize: '13px', backgroundColor: '#F9F9F9', borderLeft: '4px solid #BFDBF5' , width: '195px'}}>
								<strong>
									{ ticket.customer}
								</strong>
								</G.CardBodyText>
                <G.CardBodyText style={{ justifyContent: 'flex-start',  backgroundColor: '#F9F9F9',  width: '195px', padding: '6px 8px' }}>
									 <NoteAddIcon style={{  fontSize: '27px', marginRight: '10px', color: '#333' }}/> 
                   {moment(ticket.create_time).format("YYYY-MM-DD HH:mm")}
								</G.CardBodyText>
                <div style={{flexDirection: 'row', width: '220px', display: 'flex', height: '40px', justifyContent: 'space-between'}}>
								<G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '80px' }}>
									<BusinessIcon style={{ marginRight: '10px', fontSize: '26px' }}/>
									{ticket.customer_id}
								</G.CardBodyText>
                <G.CardBodyText style={{ justifyContent: 'start', padding: '6px 8px', width: '100px' }}>
                <G.InfoText style={{backgroundColor: '#444', color: '#fff', padding: '4px 5px', borderRadius: '5px', fontWeight: 'bold', marginRight: '10px'}}>SLA</G.InfoText>
									{ticket.sla}
								</G.CardBodyText>
                </div>  
								{/* <G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '200px' }}>
								<AccountBoxIcon style={{ fontSize: '26px', marginRight: '10px' }} />
								{ticket.responsible }
								</G.CardBodyText> */}
 
								<G.CardBodyText style={{ justifyContent: 'start', padding: '6px 8px', width: '195px' }}>
                <TypeSpecimenIcon style={{  fontSize: '26px', marginRight: '10px' }} /> 
									{ticket.queue}
								</G.CardBodyText>      
								<G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '200px' }}>
                  <G.InfoText style={{backgroundColor: '#444', color: '#fff', padding: '4px 5px', borderRadius: '5px', fontWeight: 'bold', marginRight: '10px', marginLeft: '2px'}}>TP</G.InfoText>
									{ticket.type }
									</G.CardBodyText>
                  <G.CardBodyText style={{ justifyContent: 'flex-start', padding: '6px 8px', width: '200px' }}>
                  <MiscellaneousServicesIcon style={{ marginRight: '10px', fontSize: '26px' }} /> 
									{ticket.service }
									</G.CardBodyText>
								</G.CardBodyA>
				</G.CardBody>
				<G.CardFooter style={{ padding: !showArticle ? '5px' : '15px', margin: '0px', backgroundColor: '#fff', width: !showArticle ?'235px' : '550px', marginLeft: !showArticle ? '2px' : '-330px', 
                               boxShadow: !showArticle ? '' : '0px 0px 2px 1px #999', transition: 'all 0s ease-in-out',  borderRadius: '7px', height: !showArticle ? '165px' : '365px',
                               marginTop:  !showArticle ? '0px' : '-200px', boxShadow: showArticle ? '0px 3px 7px rgba(180, 180, 180, .9' : 'none' }}>
					<G.CardHeaderInfo style={{ padding: '7px', fontSize: '12px',  margin: '-30px 0px 0px 0px', width: '100%' }}>
          {!showReply && 
          <>   
  				<G.CardHeaderNumber style={{ margin: '10px 0px', fontWeight: 'bold',  color: '#333', justifyContent: 'space-between', width: '100%', minWidth: '240px'}}>
            <div style={{}}>
                <G.Text style={{marginBottom: '5px'}}>{!showArticle ? ticket.a_from.replace(/ *\<[^)]*\> */g, '') : ticket.a_from}
                </G.Text>
                <G.Text style={{padding: '3px 0px',fontSize: '11px', fontWeight: 'normal', borderTop: '1px solid #333'}}>
                  <G.Text style={{marginRight: '2px'}}>Última Interação:</G.Text>
                  <strong>{moment(ticket.a_create_time).format("YYYY-MM-DD HH:mm")}</strong>
                  </G.Text>
            </div>
            {!showArticle 
            ? <ArrowBackIosIcon style={{marginBottom: '5px', cursor: 'pointer'}} onClick={() => setShowArticle(showArticle ? false : true)} />
            : <ArrowForwardIosIcon style={{cursor: 'pointer'}} onClick={() => setShowArticle(showArticle ? false : true)} />
            }
					</G.CardHeaderNumber>
					<G.TextViewScroll style={{ marginLeft: '3px' , width: showArticle ? '100%' : '95%', height:  !showArticle ? '100px' :'300px', padding: '0px', backgroundColor: '#fff', marginBottom: '-20px', borderRadius: '0px' }}>
            <div style={{ width: '95%', fontSize: '13px'}} dangerouslySetInnerHTML={{ __html: ticket.a_body.replace(/\n/g, "<br/>") }}></div>
          </G.TextViewScroll>
          </>
          }
          {showReply &&
           <div onMouseOver={() => setShowButton(false)} style={{ flex: '1', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <ReactQuill onChange={setReply} value={reply} />
              <div style={{flexDirection: 'row', width: '100px', display: 'flex', marginLeft: '-90px', height: '40px', alignItems: 'center', justifyContent: 'end'}}>
                <G.IconButton onClick={() => setShowReplyTemplate(true)}  style={{ background: '#333'}}>
                  <KeyboardArrowDownIcon /> 
                </G.IconButton>
                <G.IconButton onClick={() => setShowArticle(false) + setShowReply(false)} style={{backgroundColor: '#E95839'}}><CloseIcon/></G.IconButton>
              </div>
              {showReplyTemplate &&
              <div onMouseLeave={() => setShowReplyTemplate(false)} style={{width: '1px', height: '1px'}}>
                <G.PopUpAreaFlex style={{marginLeft: '-350px', marginTop: '0px', height: '300px', padding: '10px'}}>
                <G.PopUpBodyScroll>  
                {template.map(u => (
                          <G.PopUpSelector onClick={() => setReply('Prezado(a) ' + ticket.customer + ', <br/><br/>' + u.text)}  key={u.id} value={u.id}>
                            <AddCircle style={{marginRight: '5px'}}/>
                            <G.Text style={{marginTop: '-3px'}}>
                              {u.name}
                            </G.Text>
                          </G.PopUpSelector>
                        ))}
                </G.PopUpBodyScroll>        
                </G.PopUpAreaFlex>
              </div>
              }
            </div>
            {file &&
            <div style={{justifyContent: 'flex-end', display: 'flex'}}>
              <div style={{display: 'flex', backgroundColor: '#555', position: 'absolute', marginTop: '-40px', boxShadow: '0px 2px 3px rgba(180, 180, 180, .9', flexDirection: 'row', color: '#fff', borderRadius: '5px'}}>
                <AttachFileIcon style={{ color: '#000', fontSize: '22px', backgroundColor: '#ffb400', padding: '5px', marginRight: '10px', borderRadius: '5px 0px 0px 5px'}}/>
                <G.Text style={{fontSize: '11px'}}>{file.name.length > 30 ? file.name.substring(0,30)+' ...' : file.name }</G.Text>
                <CloseIcon onClick={() => setFile(false)} style={{cursor: 'pointer', fontSize: '22px',backgroundColor: '#E95839', padding: '5px', borderRadius: '0px 5px 5px 0px', marginLeft: '10px'}}/>  
              </div>
            </div>
            }
            <G.InfoArea style={{ marginBottom: '-35px', justifyContent: 'space-between', width: '100%', marginLeft: '-15px'}}>
               <G.FormInputArea  >
                  <G.FormInputLabelIcon>
                    <AutorenewIcon style={{fontSize: '22px'}} /> 
                  </G.FormInputLabelIcon>
                     <G.FormSelect value={selectedReplyState} onChange={(e) => setSelectedReplyState(e.target.value) + setSelectedReplyDate(false)} >
                       <option value="">Selecione</option>
                        {ticketState.filter(s => s.type_id !== 1).map(u => (
                          <option key={u.id} value={[u.id, u.type_id]}>{u.name}</option>
                        ))}
                      </G.FormSelect>                                           
                </G.FormInputArea>
                <G.FormInputArea style={{width: '180px', border: '0px solid #ccc'}}>
                  {(selectedReplyState && selectedReplyState.split(',')[1].trim() === '4') &&
                  <G.FormInput onChange={(e) => setSelectedReplyDate(e.target.value)} value={selectedReplyDate} type="datetime-local" min={moment().format("YYYY-MM-DDTHH:MM")}/>
                  }                 
                </G.FormInputArea>
               <div style={{flexDirection: 'row', display: 'flex'}}>
               <label htmlFor="formId"><G.IconButton onClick={() => null} style={{backgroundColor: '#333'}}><AttachFileIcon fontSize='small'/></G.IconButton></label>
               <G.Input id='formId' type="file" style={{ display: 'none' }} onChange={e => setFile(e.target.files[0])}/> 
               <G.IconButton onClick={() =>  replyValidate ? postArticle([{
                                                                           "ticket_id": ticket.id,
                                                                           "is_visible_for_customer": 1,
                                                                           "create_by": user.id,
                                                                           "change_by": user.id 
                                                                          },
                                                                          {
                                                                           "incoming_time":  moment().unix(),
                                                                           "a_from": ticket.responsible,
                                                                           "a_to": ticket.customer,
                                                                           "a_body": reply,
                                                                           "a_subject": ticket.a_subject
                                                                          }
                                                                          ])
                + postTicket({'id': ticket.id, 'ticket_state_id': selectedReplyState.split(',')[0], 'ticket.until_time': selectedReplyDate ? moment(selectedReplyDate).unix() : 0})
                : null } 
                 style={{backgroundColor: replyValidate ? '#2E8B57' : '#ccc'}}>
                <PlayArrow/></G.IconButton>
              </div>
            </G.InfoArea>
           </div>
          }
					</G.CardHeaderInfo>
				</G.CardFooter>
					</G.Card>
          <div onMouseOver={() => setShowButton(true)} style={{flexDirection: 'column', display: 'flex', marginTop: '20px' , marginLeft: '-65px' , zIndex: '3', backgroundColor: '#fdfffc', padding: '7px 5px', transition: 'all 0.5s ease', 
            visibility: showButton ? 'visible' : 'hidden', borderRadius: '5px', height: '120px', boxShadow: '0px 0px 0px 1px #85929e'}}>          
          <G.IconButton onClick={() => setModal(2)}  style={{ color: '#777', backgroundColor: '#d9d9d9', margin: '4px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
          <G.IconButton onClick={() => setShowArticle(true) + setShowReply(true)} style={{ color: '#777', backgroundColor: '#d9d9d9', margin: '4px 1px'}}><ReplyIcon fontSize="small"/> </G.IconButton>
          <G.IconButton style={{ color: '#777', backgroundColor: '#d9d9d9', margin: '4px 1px' }}><DeleteIcon fontSize="small" /></G.IconButton>
         </div>
          </div>
					}
        <G.CardHeader style={{margin: '10px', justifyContent: 'end', backgroundColor: '#fff', width: '30px', height: '66px', flexDirection: 'column' }}>
          <G.IconButton onClick={() => GoClick('/Tickets') + setFile(false) + setReply('') } style={{ color: '#555', backgroundColor: '#fff0e9', margin: '2px 0px' }}><CloseIcon fontSize="small" /></G.IconButton>
          <G.IconButton  onClick={() => setShowDash(showDash ? false : true)} style={{ color: '#555', backgroundColor: '#d9d9d9', margin: '5px 0px' }} >
          <DashboardIcon fontSize="small" /></G.IconButton>
        </G.CardHeader>
			</G.PainelBox>
      {modal &&
         <M.ModalContainer style={{backgroundColor: 'hsl(0deg 0% 0% / 0.5)'}}>
         <M.ModalContentSmall style={{boxShadow: ' 3px 4px 4px #888', backgroundColor: '#fff', borderRadius: '10px', padding: '20px 40px 10px 40px'}} >
         <G.BoxHeader  style={{}}>
             <G.FormInputArea>
               <G.FormInputLabel style={{ borderLeft: '3px solid blue', width: '140px' }}>Chamado</G.FormInputLabel> 
               <G.FormInput hidden="true" defaultValue={ticket.id} {...register("id")}/>                      
             <G.FormInput readOnly={true} defaultValue={ticket.tn} {...register("tn")}/>                      
             </G.FormInputArea>
         </G.BoxHeader>  
         <G.BoxBody style={{ margin: '0px 0px',  justifyContent: 'start', flexDirection: 'column', width: '90%'}}>
                 <G.FormInputArea>
                   <G.FormInputLabel style={{ borderLeft: '3px solid blue' }}>Titulo</G.FormInputLabel> 
                   <G.FormInput defaultValue={ticket.title} {...register("title")}/>                      
                 </G.FormInputArea>
                 <G.FormInputArea  >
                   <G.FormInputLabelIcon>
                     <AutorenewIcon style={{  fontSize: '26px'}} /> 
                   </G.FormInputLabelIcon>
                      <G.FormSelect hidden={modal !== 2 ? true : null} {...register("ticket_state_id")} >
                      <option value={modal !== 2 ? '4' : ticket.ticket_state_id }>{ticket.state}</option>
                         {ticketState.map(u => (
                           <option key={u.id} value={u.id}>{u.name}</option>
                         ))}
                       </G.FormSelect>                     
                 </G.FormInputArea>
                 <G.FormInputArea  >
                   <G.FormInputLabelIcon>
                     <G.InfoText style={{backgroundColor: '#444', color: '#fff', padding: '4px 5px', borderRadius: '5px', fontWeight: 'bold'}}>TP</G.InfoText>
                   </G.FormInputLabelIcon>
                      <G.FormSelect {...register("type_id")} >
                      <option value={ticket.type_id}>{ticket.type}</option>
                         {type.map(u => (
                           <option key={u.id} value={u.id}>{u.name}</option>
                         ))}
                       </G.FormSelect>                     
                 </G.FormInputArea>
                 <G.FormInputArea >
                   <G.FormInputLabelIcon>
                     <TypeSpecimenIcon style={{  fontSize: '26px'}} /> 
                   </G.FormInputLabelIcon>
                      <G.FormSelect {...register("queue_id")} >
                      <option value={ticket.queue_id}>{ticket.queue}</option>
                         {queue.map(u => (
                           <option key={u.id} value={u.id}>{u.name}</option>
                         ))}
                       </G.FormSelect>                     
                 </G.FormInputArea>
                 <G.FormInputArea >
                   <G.FormInputLabelIcon >
                     <MiscellaneousServicesIcon style={{ fontSize: '26px' }} /> 
                   </G.FormInputLabelIcon>
                      <G.FormSelect  {...register("service_id")} >
                      <option value={ticket.service_id}>{ticket.service}</option>
                         {service.map(u => (
                           <option key={u.id} value={u.id}>{u.name}</option>
                         ))}
                       </G.FormSelect>                     
                 </G.FormInputArea>
                 <G.FormInputArea  >
                   <div style={{flexDirection: 'row', width: '100%', display: 'flex'}}>
                   <G.FormInputLabelIcon >
                   <G.InfoText style={{backgroundColor: '#444', color: '#fff', padding: '4px 5px', borderRadius: '5px', fontWeight: 'bold'}}>SLA</G.InfoText>
                   </G.FormInputLabelIcon>
                    <G.FormSelect  {...register("sla_id")} >
                      <option value={ticket.sla_id} >{ticket.sla}</option>
                         {sla.map(u => (
                           <option key={u.id} value={u.id}>{u.name}</option>
                         ))}
                       </G.FormSelect>
                   </div>
                   <div style={{flexDirection: 'row', width: '100%', display: 'flex'}}>
                   <G.FormInputLabelIcon >
                     <LowPriorityIcon style={{  fontSize: '26px'}} /> 
                   </G.FormInputLabelIcon>
                    <G.FormSelect    {...register("ticket_priority_id")} >
                      <option value={ticket.ticket_priority_id}>{ticket.priority}</option>
                         {priority.map(u => (
                           <option key={u.id} value={u.id}>{u.name}</option>
                         ))}
                       </G.FormSelect>
                   </div>                          
                 </G.FormInputArea>
               </G.BoxBody>
               <G.BoxBody style={{ margin: '0px 0px',  justifyContent: 'center', flexDirection: 'column', width: '90%' }}>
               <G.FormInputArea >
                   <G.FormInputLabelIcon style={{ borderLeft: '3px solid #33309d' }} >
                   <G.Image style={{ borderRadius: '50%', backgroundColor: '#777', width: '28px'}} src={ticket.owner_photo ? ticket.owner_photo : '/person.png'} />
                   </G.FormInputLabelIcon>
                      <G.FormSelect  {...register("user_id")} >
                      <option value={ticket.user_id}>{ticket.owner}</option>
                         {users.map(u => (
                           <option key={u.id} value={u.id}>{u.first_name + ' ' + u.last_name}</option>
                         ))}
                       </G.FormSelect>                     
                 </G.FormInputArea>
                 <G.FormInputArea >
                   <G.FormInputLabelIcon style={{ borderLeft: '3px solid #277384' }} >
                   <G.Image style={{ borderRadius: '50%', backgroundColor: '#bfdbf5', width: '28px'}} src={ticket.resp_photo ? ticket.resp_photo : '/person.png'} />
                   </G.FormInputLabelIcon>
                      <G.FormSelect  {...register("responsible_user_id")} >
                      <option value={ticket.responsible_user_id} >{ticket.responsible}</option>
                         {users.map(u => (
                           <option key={u.id} value={u.id}>{u.first_name + ' ' + u.last_name}</option>
                         ))}
                       </G.FormSelect>                     
                 </G.FormInputArea>
 
               </G.BoxBody>
               <G.BoxFooter style={{ padding: '5px', flexDirection: 'row'}}>
                 <G.Button onClick={() => setModal(false) + reset()} style={{backgroundColor: '#E95839'}}>Fechar</G.Button>
                 <G.Button onClick={() => handleSubmit(postTicket)()} style={{backgroundColor: '#2E8B57'}}>Reclassificar</G.Button>
               </G.BoxFooter>
         </M.ModalContentSmall>
       </M.ModalContainer>

      }
      </div>


	)

}
